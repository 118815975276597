import { parseWithZod } from "@conform-to/zod"
import type {
	ActionFunctionArgs,
	LoaderFunctionArgs,
	MetaFunction,
} from "@remix-run/node"
import { json } from "@remix-run/node"
import { z } from "zod"

import { login, requireAnonymous } from "~/utils/auth.server"
import { checkHoneypot } from "~/utils/honeypot.server"
import { handleNewSession } from "~/utils/login.server"
import LoginPage from "./components/LoginPage"
import { LoginSchema } from "./schema"

export const meta: MetaFunction = () => {
	return [{ title: "Login to Saga OS" }]
}

const LoginRoute = () => <LoginPage />

export const loader = async ({ request }: LoaderFunctionArgs) => {
	await requireAnonymous(request)
	return null
}

export const action = async ({ request }: ActionFunctionArgs) => {
	await requireAnonymous(request)
	const formData = await request.formData()
	checkHoneypot(formData)
	const submission = await parseWithZod(formData, {
		schema: (intent) =>
			LoginSchema.transform(async (data, ctx) => {
				if (intent !== null) return { ...data, session: null }

				const session = await login(data)
				if (!session) {
					ctx.addIssue({
						code: z.ZodIssueCode.custom,
						message: "Invalid username or password",
					})
					return z.NEVER
				}

				return { ...data, session }
			}),
		async: true,
	})

	if (submission.status !== "success" || !submission.value.session) {
		return json(
			{ result: submission.reply({ hideFields: ["password"] }) },
			{ status: submission.status === "error" ? 400 : 200 },
		)
	}

	const { session, redirectTo } = submission.value

	return handleNewSession({
		request,
		session,
		remember: true,
		redirectTo,
	})
}

export default LoginRoute
