import { Icon } from "@sagaware/ui-icons/Icon"
import {
	Card,
	CardContent,
	CardHeader,
	CardTitle,
} from "@sagaware/ui-shadcn/ui/card"

import { LoginForm } from "../components/LoginForm"

export default function LoginPage() {
	return (
		<div className="flex min-h-screen w-full flex-col items-center justify-center p-6">
			<Card className="w-full max-w-sm border-0">
				<CardHeader>
					<div className="flex flex-col items-center gap-2">
						<Icon name="Pyramid" className="mr-2 size-20 text-pink-500" />
						<CardTitle className="flex text-2xl">Login to Saga OS</CardTitle>
						{/* <CardDescription>
              Enter email and password to enter.
            </CardDescription> */}
					</div>
				</CardHeader>
				<CardContent className="grid gap-4">
					<LoginForm />
				</CardContent>
			</Card>
		</div>
	)
}
