import { z } from "zod"

export const LoginSchema = z.object({
	email: z.preprocess(
		(value) => (value === "" ? undefined : value),
		z
			.string({ required_error: "Email is required" })
			.email("Not a valid email address"),
	),
	password: z
		.string({ required_error: "Password is required" })
		.min(8, { message: "Password is too short" }),
	redirectTo: z.string().optional().default("/"),
})
