import { getFormProps, getInputProps, useForm } from "@conform-to/react"
import { getZodConstraint, parseWithZod } from "@conform-to/zod"
import { Form, useActionData, useSearchParams } from "@remix-run/react"
import { HoneypotInputs } from "remix-utils/honeypot/react"

import { ButtonWithStatus } from "@sagaware/ui-shadcn/custom/ButtonWithStatus"
import { useIsPending } from "@sagaware/utils/pending"

import { ErrorList, Field } from "~/components/forms"
import type { action } from "../route"
import { LoginSchema } from "../schema"

export function LoginForm() {
	const actionData = useActionData<typeof action>()
	const isPending = useIsPending()
	const [searchParams] = useSearchParams()
	const redirectTo = searchParams.get("redirectTo") ?? "/"

	const [form, fields] = useForm({
		id: "login-form",
		constraint: getZodConstraint(LoginSchema),
		defaultValue: { redirectTo },
		lastResult: actionData?.result,
		onValidate({ formData }) {
			return parseWithZod(formData, { schema: LoginSchema })
		},
		shouldRevalidate: "onBlur",
	})

	return (
		<div className="mx-auto w-full max-w-md px-8">
			<Form method="POST" {...getFormProps(form)}>
				<HoneypotInputs />

				<Field
					labelProps={{ children: "Email" }}
					inputProps={{
						...getInputProps(fields.email, { type: "text" }),
						autoFocus: true,
						className: "lowercase",
						autoComplete: "email",
					}}
					errors={fields.email.errors}
				/>

				<Field
					labelProps={{ children: "Password" }}
					inputProps={{
						...getInputProps(fields.password, {
							type: "password",
						}),
						autoComplete: "current-password",
					}}
					errors={fields.password.errors}
				/>

				<input {...getInputProps(fields.redirectTo, { type: "hidden" })} />
				<ErrorList errors={form.errors} id={form.errorId} />

				<div className="flex items-center justify-between gap-6 pt-3">
					<ButtonWithStatus
						className="w-full"
						status={isPending ? "pending" : (form.status ?? "idle")}
						type="submit"
						disabled={isPending}
					>
						Log in
					</ButtonWithStatus>
				</div>
			</Form>
		</div>
	)
}
